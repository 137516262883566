.p-frame {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
}
.p-frame:not(:first-child) {
  margin-top: 16px;
}
.p-frame__header {
  width: 100%;
  border-radius: var(--radius-md) var(--radius-md) 0 0;
  background-color: var(--gray50);
  padding: 12px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-frame__header__ttl {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
}
.p-frame__header__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-frame__header__actions > *:not(:last-child) {
  margin-right: 12px;
}
.p-frame__body {
  border-top: 1px solid var(--gray200);
  border-radius: 0 0 var(--radius-md) var(--radius-md);
  padding: 20px;
  /* TODO: for now set as block
  display: none; */
  display: block;
}
.p-frame__body.gray {
  background-color: var(--gray50);
}
.p-frame__body.is-active {
  display: block;
}
.p-frame__body__note {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-top: 6px;
}
.p-list {
}
.p-list__item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-list__item:not(:first-child) {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--gray200);
}
.p-list__item__ttl {
  width: 240px;
  color: var(--gray500);
  flex-shrink: 0;
  margin-right: 32px;
}
.p-list__item__ttl.full {
  width: 100%;
  margin-bottom: 4px;
}
.p-list__item__ttl__txt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-list__item__ttl__txt > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 4px;
}
/* TODO: we can remove this span later */
.p-list__item__ttl__txt span {
  content: attr(data-required);
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--white);
  background-color: var(--warning500);
  border-radius: var(--radius-full);
  padding: 2px 6px;
  margin-left: auto;
}
/* TODO: this is to position badge */
.p-list__item__ttl__txt p {
  margin-left: auto;
}

.p-list__item__ttl > span {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-top: 4px;
}
/* TODO: translation do this later, or delete if not needed */
.p-list__item__ttl__translationHeader {
  min-width: 240px;
  margin-right: 32px;
}
.p-list__item__translationHeader {
  display: flex;
  justify-content: space-around;
  width: calc(100% - 240px - 32px);
  font-weight: var(--text-semibold);
}
@media screen and (max-width: 768px) {
  .p-list__item__translationHeader {
    width: 100%;
  }
}
.p-list__item.p-list__item__agentReference {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}
.p-list__item__body {
  width: calc(100% - 240px - 32px);
}
.p-list__item__body__detail > ul {
  width: 100%;
}
.p-list__item__body__detail > ul > li:not(:first-child) {
  margin-top: 0;
  border-top: none;
}
.p-list__item__body.row {
  flex-direction: column;
}
.p-list__item__body.row > *:not(:first-child) {
  margin-top: 8px;
}
.p-list__item__body__calendar {
  display: flex;
  align-items: center;
}
.p-list__item__body__calendar > p {
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin: 0;
}
.p-list__item__body.fullWidth {
  width: 100%;
}
.p-list__item__body.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -12px;
}
.p-list__item__body.flex > * {
  margin-top: 12px;
}
.p-list__item__body.flex > *:not(:last-child) {
  margin-right: 12px;
}
.p-list__item__body.flex > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
}
.p-list__item__body.flex > div:not(:last-child) {
  margin-right: 12px;
}
.p-list__item__body.flex > div > div:last-child {
  margin-left: 4px;
}
.p-list__item__body.flex > p {
  width: 100%;
}
.p-list__item__body__flex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-list__item__body__flex__main {
  width: 100%;
}
.p-list__excludedDates {
  display: flex;
  align-items: center;
}
.p-list__excludedDates > * {
  flex: 1;
}
.p-list__excludedDates > *:not(:first-child) {
  margin-left: 8px;
}
.p-list__highlight {
  display: flex;
}
.p-list__highlight > *:not(:first-child) {
  margin-left: 4px;
}
.p-list__item__body__flex__actions {
  width: 36px;
  margin-left: 8px;
  flex-shrink: 0;
}
/* TODO: translation do this later, or delete if not needed */
.p-list__item__body__flex__translation {
  min-width: 50%;
}
.p-list__item__body__flex > *:not(.p-list__item__body__flex__main),
.p-list__item__body__flex > *:not(.p-list__item__body__flex__actions) {
  margin-right: 12px;
}
.p-list__item__body__flex > *:last-child {
  margin-right: 0;
}
.p-list__item__body__flex.full {
  width: 100%;
}
.p-list__item__body__flex.full > * {
  width: 100%;
  flex-shrink: 1;
}
.p-list__item__body__photo {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: -16px;
}
.p-list__item__body__photo li {
  width: 128px;
  height: 128px;
  border: 1px solid var(--gray200);
  border-radius: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;
}
.p-list__item__body__photo li img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.p-list__item__body__photo li a {
  width: 24px;
  height: 24px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -4px;
  right: -4px;
}
.p-list__item__body__photo li a i {
  font-size: 16px;
  color: var(--white);
}
.p-list__item__body__photo li.add {
  border: 1px dashed var(--primary700);
  color: var(--primary700);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.p-list__item__body__photo li.add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.p-list__item__body__photo li.add p {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  margin-top: 10px;
}
.p-list__item__body__list > div:not(:first-child) {
  margin-top: 8px;
}
.p-list__item__body__list:not(:first-child) {
  margin-top: 16px;
}
.p-list__item__body__list__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  color: var(--gray500);
  margin-bottom: 4px;
}

.p-list__item.row {
  flex-direction: column;
}
.p-list__item.row > .p-list__item__ttl {
  width: 100%;
  margin-bottom: 16px;
  margin-right: 0;
}
.p-list__item.row > .p-list__item__body {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .p-list__item {
    flex-direction: column;
  }
  .p-list__item__ttl {
    width: 100%;
    margin-right: 0;
    margin-bottom: 12px;
  }
  .p-list__item__ttl > p {
    width: 100%;
  }
  .p-list__item__ttl > p span {
    margin-left: 8px;
  }
  .p-list__item__body {
    width: 100%;
  }
  .p-list__item__body__photo li {
    width: 100px;
    height: 100px;
  }
  .p-list__item__body__flex.full {
    flex-direction: column;
    margin-top: 16px;
  }
  .p-list__item__body__flex.full > * {
    margin-right: 0;
  }
  .p-list__item__body__flex.full > *:not(:first-child) {
    margin-top: 16px;
  }
}
