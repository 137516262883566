.p-equipmentScheduleEditor__rule__select {
  margin-bottom: 16px;
}
.p-equipmentScheduleEditor__rule__select__ttl {
  margin-bottom: 4px;
}
.p-equipmentScheduleEditor__rule__select__box {
  display: flex;
}
.p-equipmentScheduleEditor__rule__select__box a {
  height: 40px !important;
  margin-left: 16px;
  flex-shrink: 0;
}

.p-equipemntScheduleEditor__rule__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin-top: 16px; */
}
.p-equipemntScheduleEditor__rule__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
