.list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -12px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 59px;
  height: 28px;
  background: #f4f4f5;
  border-radius: 5px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  margin-right: 4px;
}

.item.is_active {
  background: #f0faff;
}
